import _ from "lodash";
import {mapActions, mapMutations} from "vuex";

export default {
  name: "category-select",
  props: {
    childFilter: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      selectedCategory: {}
    }
  },
  watch: {
    '$route'(newVal) {
      if (newVal) {
        if (!this.$route.query.slug) {
          this.selectedCategory = {}
        }
      }
    }
  },
  computed: {},
  created() {
    if (this.$route.query.slug) {
      this.selectedCategory.slug = this.$route.query.slug
      this.$emit('show', true)
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'products/GET_FILTER_PRODUCT'
    }),
    ...mapMutations({

    }),
    selectFilter() {
      this.$router.push({
        name: 'products',
        query: Object.assign({}, this.$route.query, this.selectedCategory)
      }).catch(() => {
        console.log('')
      });
      this.sendRequest();
    },
    sendRequest: _.debounce(function () {
      this.fetchList({data: this.$route.query});
    }, 300),
  }
}