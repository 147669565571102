import product from '@/components/product/index.vue'
import categoryFilter from "./components/category-filter/index.vue";
import sortSelect from "./components/sort-select/index.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  name: "catalog",
  components:{
    product,
    categoryFilter,
    sortSelect
  },
  watch:{
    '$route'(newVal){
      if(newVal){
        if(this.$route.query.slug || this.$route.query.filter_type) {
          // this.resetLimit()
          this.fetchFilterProducts({data:this.$route.query})
        }else{
          // this.resetLimit()
          this.fetchProducts()
        }
      }
    }
  },
  created() {
    if(this.$route.query.slug || this.$route.query.filter_type ) {
      this.fetchFilterProducts({data:this.$route.query})
    }else{
      this.fetchProducts()
    }
    this.fetchFilters()
  },
  computed:{
    ...mapGetters({
      products:'products/list',
      filters:'products/filters',
      loadingList:'products/loadingList',
      loadingFilters:'products/loadingFilters',
      limit:'products/limit',
      skip:'products/skip',
      hidePagination:'products/hidePagination',

      isAuthenticated: 'auth/isAuthenticated',
      favourites: 'favorites/whichList',
    }),
    list(){
      if (this.isAuthenticated) {
        if (!this.products) return []
        if (!this.favourites) return []
        return this.products.map(e => {
          const item = this.favourites.find(t => t.productId === e.id)
          e.selected = !!item
          return e
        })
      } else {
        return this.products
      }
    }
  },
  methods:{
    ...mapActions({
      fetchProductsFilter:'products/GET_FILTER_PRODUCT',
      fetchProducts:'products/GET_LIST',
      fetchFilterProducts:'products/GET_FILTER_PRODUCT',
      fetchFilters:'products/GET_FILTERS',
    }),
    ...mapMutations({
      changeLimit:'products/CHANGE_LIMIT',
      resetLimit:'products/RESET_LIMIT'
    }),
    resetFilter(){
      this.$router.push({
        name: 'products',
      }).catch(() => {console.log('')});
    },
    incrementLimit(){
      this.changeLimit(6)
      if(this.$route.query.slug || this.$route.query.rating ) {
        this.fetchProductsFilter({data:this.$route.query})
      }else{
        this.fetchProducts()
      }
    }
  }
}