import typeSelect from './components/select/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    hover: {
      type: Boolean,
      default: true
    }
  },
  components: {
    typeSelect
  },
  name: "product",
  data() {
    return {
      selectedType: {},
      favoriteSelect: false,
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      favourites: 'favorites/whichList',
    }),

  },
  created() {
    if (this.info) {
      if (this.info.productPrices.data.length)
        this.selectedType = this.info.productPrices.data[0]
    }

  },
  methods: {
    ...mapMutations({
      addToBasket: `basket/ADD_ITEM_TO_BASKET`,
      changeLoginPopup: 'popups/CHANGE_LOGIN_POPUP',
      changeFastBuyPopup: `popups/CHANGE_FAST_BUY_POPUP`,
    }),
    ...mapActions({
      addToFavorite: 'favorites/ADD_PRODUCT_TO_FAVOURITES',
      removeFromFavorite: 'favorites/REMOVE_PRODUCT_IN_FAVORITE',
      getFavorites: 'favorites/GET_FAVORITES_LIST',
    }),
    openFastBuy() {
      this.changeFastBuyPopup({
        show: true,
        product: {
          select_count: 1,
          select_type: this.selectedType,
          ...this.info
        }
      })
    },
    addBasket() {
      if (this.info.productPrices.data.length) {
        this.addToBasket({
          select_count: 1,
          select_type: this.selectedType,
          ...this.info
        })
        this.$toasted.success(this.$t('successAddBasket'));
      } else {
        this.addToBasket({
          select_count: 1,
          ...this.info
        })
        this.$toasted.success(this.$t('successAddBasket'));
      }
    },
    changeFavorite() {
      if (this.isAuthenticated) {
        const index = this.favourites.find(e => e.productId === this.info.id)
        if (index) {
          this.removeFromFavorite(index.id).then(() => {
            this.getFavorites()
            this.favoriteSelect = false
            this.$toasted.success(this.$t('successRemoveFromFavorite'))
          })
        } else {
          this.addToFavorite({product_id: this.info.id}).then(() => {
            this.favoriteSelect = true
            this.$toasted.success(this.$t('successAddToFavorites'));
            this.getFavorites()
          })
        }
      } else {
        this.changeLoginPopup(true)
      }
    }
  }
}
