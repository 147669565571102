import _ from "lodash";
import {mapActions} from "vuex";

export default {
  name: "sort-select",
  data() {
    return {
      list: [
        {
          id: 0,
          key: '',
          name: this.$t('default')
        },
        {
          id: 1,
          key: '0',
          name: `${this.$t('rating')} ↑`
        },
        {
          id: 2,
          key: '1',
          name: `${this.$t('rating')} ↓`
        },
        {
          id: 4,
          key: '3',
          name: 'А-Я'
        },
        {
          id: 3,
          key: '2',
          name: 'Я-А'
        },
        {
          id: 5,
          key: '4',
          name: `${this.$t('priceSort')} ↓`
        }, {
          id: 6,
          key: '5',
          name: `${this.$t('priceSort')} ↑`
        },
      ],
      currentSort: {
        id: 0,
        key: '',
        name: this.$t('default')
      },
      active: false,
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'products/GET_FILTER_PRODUCT'
    }),
    changeSort(sort) {
      this.currentSort = sort
      this.$router.push({
        name: 'products',
        query: Object.assign({}, this.$route.query, {'filter_type': this.currentSort.key})
      }).catch(() => {
        console.log()
      })
      console.log('222', this.$route.query)
      this.sendRequest()
    },
    sendRequest: _.debounce(function () {
      this.fetchList({data: this.$route.query});
    }, 300),
  }
}