import {VueSlideToggle} from "vue-slide-toggle";
import categorySelect from '../category-select/index.vue'

export default {
  name: "category-filter",
  props: {
    filter: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      open: false,
    }
  },
  components: {
    VueSlideToggle,
    categorySelect
  },
  methods: {
    changeSlideToggle(val) {
      this.open = val
    }
  }
}